<script setup lang="ts">
import useVuelidate from "@vuelidate/core";

const { t } = useI18n();

interface stateStepRules {
  message: {};
}

const props = defineProps<{
  state: {
    message: string;
    files: string[];
  };
  rules: stateStepRules;
}>();

const { rules, state } = toRefs(props);

// Rules
const stateRules = reactive({
  ...rules.value,
});
const v3 = useVuelidate(stateRules, state);

// File Upload
const handleImagesUpdate = (images: string[]) => {
  state.value.files = images;
};
</script>

<template>
  <label
    class="text-scheppach-primary-500 text-sm md:text-base"
    for="errorDescription"
  >
    {{ t("form.contactForm.errorDescription") }} *
    <textarea
      id="errorDescription"
      v-model="state.message"
      required
      class="mb-3 h-200px placeholder-text-scheppach-neutral-500 text-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b"
      :placeholder="t('form.contactForm.errorDescriptionPlaceholder')"
      :class="[
        v3.message.$error ? 'b-scheppach-error-500' : 'b-scheppach-primary-700',
      ]"
      @blur="v3.message.$touch()"
    >
    </textarea>
    <p
      v-if="v3.message.$error"
      class="mt--3 text-sm c-scheppach-error-500 focus:ring-primary border-secondary-300"
    >
      {{ v3.message.$errors[0].$message }}
    </p>
  </label>

  <SharedFileUpload
    :base64-data="state.files"
    @update:base64-data="handleImagesUpdate"
  />
</template>
